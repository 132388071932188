import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Image from "../../../components/image"
import ImageFrame from "../../../components/image-frame"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"
import VectorScardanelli from "../../../images/dauerausstellung/05-haelfte-des-lebens/scardanelli.svg"
import Comment from "../../../components/comment"
import Heading from "../../../components/heading"

const HoelderlinLiebtNamen = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        titelbild: file(
          relativePath: { eq: "hoelderlin-liebt/einleitung-namen.jpg" }
        ) {
          ...mediumImage
        }
        lotte: file(relativePath: { eq: "hoelderlin-liebt/lotte-zimmer.jpg" }) {
          ...avatarImage
        }
        hoelderlin: file(
          relativePath: { eq: "hoelderlin-liebt/portrait-hoelderlin.jpg" }
        ) {
          ...avatarImage
        }
        schwab: file(relativePath: { eq: "hoelderlin-liebt/schwab.png" }) {
          ...mediumImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt die Namen"
        description="Im Turm gibt sich Hölderlin einen neuen Namen. Er will Scardanelli heißen. Mit diesem Namen unterschreibt er auch seine Gedichte."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "… die Namen",
                link: "/sonderausstellungen/hoelderlin-liebt/namen",
              },
            ]}
          />
          <PageTitle>Hölderlin liebt die Namen</PageTitle>
          <Grid columns={[1, 2]} alignY="center">
            <Stack>
              <Paragraph dropcap={true}>
                Im Turm gibt sich Hölderlin einen neuen Namen. Er will
                Scardanelli heißen. Mit diesem Namen unterschreibt er auch seine
                Gedichte.
              </Paragraph>
            </Stack>
            <ImageFrame>
              <Image
                image={data.titelbild.childImageSharp.gatsbyImageData}
                alt="Auf der Illustration stehen sich zwei Hölderlin-Figuren gegenüber. Die rechte Figur steht auf dem Kopf."
              />
            </ImageFrame>
          </Grid>
        </Stack>

        <Stack>
          <Comment
            title="Lotte"
            image={data.lotte.childImageSharp.gatsbyImageData}
            alt="Eine Illustration zeigt Lotte Zimmer. Sie hat lange Haare und neigt den Kopf zur Seite."
          >
            <Paragraph>»Nanu? Wer ist das denn? Scar-da-nel-li?«</Paragraph>
          </Comment>
          <Comment
            title="Hölderlin"
            image={data.hoelderlin.childImageSharp.gatsbyImageData}
            alt="Eine Illustration zeigt Hölderlin. Er hat lange Haare und trägt ein blaues Hemd mit großem Kragen."
            reverse={[true]}
          >
            <Paragraph>
              »Ich bin’s.«
              <br />
              »Der Scardanelli, das bin ich.«
            </Paragraph>
          </Comment>
          <Paragraph>Lotte denkt:</Paragraph>
          <Comment
            title="Lotte"
            image={data.lotte.childImageSharp.gatsbyImageData}
            alt="Eine Illustration zeigt Lotte Zimmer. Sie hat lange Haare und neigt den Kopf zur Seite."
          >
            <Paragraph>
              »Ach, der Hölderlin, jetzt ist er wieder verrückt.« <br />
            </Paragraph>
          </Comment>
          <Paragraph>
            Aber später, da erkennt Lotte etwas. <br />
            Hölderlin ist wirklich Scardanelli geworden.
          </Paragraph>
        </Stack>

        <Stack>
          <img
            src={VectorScardanelli}
            alt="Das Pesudonym Scardanelli in Hölderlins Handschrift"
            width="1000"
            height="215"
          />

          <Constrain>
            <Stack>
              <Heading as="h2" level={3}>
                Scardanelli
              </Heading>
              <Paragraph>
                Lotte erkennt, dass alles, was Hölderlin liebt, <br />
                auch in den kurzen Gedichten von Scardanelli steckt.
              </Paragraph>

              <Paragraph>
                So ist auch hier der Tag offen,
                <br />
                und die Natur grün, wohin man schaut.
                <br />
                Die Sonne scheint, und wenn sie am Abend untergeht,
                <br />
                taucht sie die Welt in einen milden Schimmer.
              </Paragraph>

              <Paragraph>
                Eines aber ist anders: <br />
                Die schöne Natur kann nicht mehr trösten. <br />
                Die Tage draußen vor der Tür machen froh, <br />
                aber die Ängste bleiben. <br />
                Der junge Hölderlin war voller Hoffnung und Liebe. <br />
                Der alte Scardanelli hat die Hoffnung verloren. <br />
                Seine Geliebte Susette Gontard ist viel zu früh gestorben.{" "}
                <br />
                Seine Familie ist weit weg. <br />
                Seine Freunde besuchen ihn nicht mehr. <br />
                Er wird alt und er versteht seine Gedanken nicht mehr.
              </Paragraph>

              <Paragraph>
                Hölderlin weiß, dass er verwirrt ist. <br />
                Er verwandelt sich in Scardanelli.
              </Paragraph>
            </Stack>
          </Constrain>
        </Stack>

        <Stack>
          <Constrain>
            <Paragraph>
              Hölderlin bekommt dieses Buch mit seinen gesammelten Gedichten
              <br />
              im Jahr 1826 von Christoph Theodor Schwab geschenkt.
              <br />
              Er nimmt das Buch entgegen.
              <br />
              Er blättert durch die Seiten.
              <br />
              Dann holt er einen Stift
              <br />
              und schreibt ein neues Gedicht in das Buch hinein.
              <br />
              Das Gedicht unterschreibt er mit seinem neuen Namen:
              »Scardanelli«.
              <br />
              Dann schenkt er das Buch Christoph Theodor Schwab wieder zurück.
            </Paragraph>
          </Constrain>
          <Constrain align="right">
            <Image
              image={data.schwab.childImageSharp.gatsbyImageData}
              alt="Ein aufgeschlagenes Buch mit einem handgeschriebenen Eintrag."
            />
          </Constrain>
        </Stack>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtNamen
